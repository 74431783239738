body {
  margin: 0;
  font-family: "Roboto" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f7fa;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

a {
  text-decoration: none;
  color: unset;
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #bbbdc3;
}

*::-webkit-scrollbar-thumb {
  background-color: #a3cf34;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgb(114, 144, 36);
}
* {
  scrollbar-width: 12px;
  scrollbar-color: #a3cf34 #bbbdc3;
}

a:hover {
  text-decoration: underline;
}

.MuiDialog-paper {
  max-width: 800px !important;
}

.MuiButton-root {
  text-transform: unset !important ;
}

.MuiContainer-root {
  max-width: 1200px !important;
  padding: 0 !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #a3cf34 !important;
}

.MuiSelect-outlined,
.MuiSelect-icon {
  color: white !important;
}
